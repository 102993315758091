const geojsonObject = {
"type": "FeatureCollection",
"name": "particelle",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3857" } },
"features": [
    { "type": "Feature", "properties": { "id": 15 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1943798.260205306578428, 4934674.069232475943863 ], [ 1943853.103109491290525, 4934546.841759900562465 ], [ 1943528.308683234266937, 4934313.528984742239118 ], [ 1943502.673082012915984, 4934372.058806857094169 ], [ 1943475.136413735337555, 4934438.538545753806829 ], [ 1943475.136413735337555, 4934444.817827851511538 ], [ 1943596.977529651019722, 4934531.921263909898698 ], [ 1943705.914432817138731, 4934609.63458087015897 ], [ 1943757.588891907827929, 4934646.273327783681452 ], [ 1943798.260205306578428, 4934674.069232475943863 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 16 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1943862.925289284437895, 4934523.913739033974707 ], [ 1943867.361112417187542, 4934526.938163897953928 ], [ 1943856.242750539211556, 4934552.717785350978374 ], [ 1944049.460293225478381, 4934691.495680499821901 ], [ 1944188.583836930571124, 4934792.71309925429523 ], [ 1944152.377150711603463, 4934927.746468383818865 ], [ 1944138.551208480028436, 4934977.174211861565709 ], [ 1944022.989374661119655, 4934896.177233621478081 ], [ 1943864.221471368335187, 4934783.7838448965922 ], [ 1943777.578900050837547, 4934723.640996190719306 ], [ 1943814.376069218851626, 4934636.782394525595009 ], [ 1943862.925289284437895, 4934523.913739033974707 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 17 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1944517.626860017189756, 4933237.035361789166927 ], [ 1944438.012476, 4933275.056702924892306 ], [ 1944415.314887503394857, 4933286.002240524627268 ], [ 1944397.571594972861931, 4933293.721724937669933 ], [ 1944461.286145423538983, 4933410.550936793908477 ], [ 1944580.995761911850423, 4933352.366763236932456 ], [ 1944539.863583773141727, 4933291.993482158519328 ], [ 1944531.568018434103578, 4933272.752379219979048 ], [ 1944525.807209170889109, 4933258.23513987660408 ], [ 1944517.626860017189756, 4933237.035361789166927 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 18 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1944461.401361607946455, 4933410.838977257721126 ], [ 1944505.778795632300898, 4933494.101873807609081 ], [ 1944606.784984712954611, 4933436.954645917750895 ], [ 1944629.136924654012546, 4933424.588108699768782 ], [ 1944652.641026447527111, 4933411.683895950205624 ], [ 1944656.481565956491977, 4933386.18271361105144 ], [ 1944651.258432224625722, 4933382.726228053681552 ], [ 1944634.360058385878801, 4933377.579905111342669 ], [ 1944593.727150383405387, 4933370.820555576123297 ], [ 1944581.206991584738716, 4933352.385965934023261 ], [ 1944461.401361607946455, 4933410.838977257721126 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 19 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1945168.96315800677985, 4934388.793957770802081 ], [ 1945204.296121487626806, 4934318.128030808642507 ], [ 1945317.054361465154216, 4934372.202827092260122 ], [ 1945282.028641145443544, 4934441.639781411737204 ], [ 1945168.96315800677985, 4934388.793957770802081 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 20 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1945281.721397984772921, 4934442.407889313064516 ], [ 1945317.207983045838773, 4934372.202827092260122 ], [ 1945521.37106333207339, 4934469.59890903532505 ], [ 1945467.296267048455775, 4934532.891000140458345 ], [ 1945336.871545330621302, 4934468.677179553546011 ], [ 1945281.721397984772921, 4934442.407889313064516 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 21 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1945521.678306492744014, 4934470.059773776680231 ], [ 1945467.757131789578125, 4934533.351864880882204 ], [ 1945654.714595076395199, 4934623.834975708276033 ], [ 1945756.412081268848851, 4934673.301124581135809 ], [ 1945800.040610088268295, 4934601.559846557676792 ], [ 1945641.503139166394249, 4934526.131650605238974 ], [ 1945560.698187901638448, 4934487.265390776097775 ], [ 1945521.678306492744014, 4934470.059773776680231 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 22 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1944747.271919943857938, 4935318.972626795992255 ], [ 1944869.247454742901027, 4935513.457547520287335 ], [ 1944897.052960786269978, 4935507.619927466847003 ], [ 1944921.786035222699866, 4935497.17366000264883 ], [ 1945048.370217431802303, 4935430.809137291274965 ], [ 1945003.205472808331251, 4935362.101885479874909 ], [ 1944990.608503219671547, 4935349.965780632570386 ], [ 1944978.933263113023713, 4935333.605082325637341 ], [ 1944947.978514672257006, 4935287.364986639469862 ], [ 1944931.464194784406573, 4935254.874022395350039 ], [ 1944921.478792061563581, 4935236.055378802120686 ], [ 1944918.175928084179759, 4935230.909055860713124 ], [ 1944907.960092990892008, 4935217.928032320924103 ], [ 1944747.271919943857938, 4935318.972626795992255 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 1 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1943614.944354484789073, 4934189.863612042739987 ], [ 1943605.189384135883301, 4934197.506285662762821 ], [ 1943605.458221901440993, 4934199.503366206772625 ], [ 1943606.956032309215516, 4934202.268554652109742 ], [ 1943592.438792970264331, 4934210.180066036991775 ], [ 1943590.28809084603563, 4934208.336607073433697 ], [ 1943577.998364422237501, 4934213.406119223684072 ], [ 1943534.677078778157011, 4934300.355933672748506 ], [ 1943538.363996705273166, 4934320.787603852339089 ], [ 1943751.129885418107733, 4934474.255562569946051 ], [ 1943848.372345746960491, 4934543.07803054433316 ], [ 1943911.049950508400798, 4934404.511365114711225 ], [ 1943614.944354484789073, 4934189.863612042739987 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 2 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1943911.337990971049294, 4934404.684189390391111 ], [ 1943962.954841945786029, 4934281.460479311645031 ], [ 1943953.814357919152826, 4934263.947619159705937 ], [ 1943845.319116845726967, 4934255.72886461392045 ], [ 1943780.106756016612053, 4934213.214092272333801 ], [ 1943715.931340854382142, 4934191.783881822600961 ], [ 1943624.219257426680997, 4934183.718748857267201 ], [ 1943622.491014648461714, 4934184.179613597691059 ], [ 1943615.117178794927895, 4934189.709990488365293 ], [ 1943911.337990971049294, 4934404.684189390391111 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 3 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1943937.751301430631429, 4934506.266459354199469 ], [ 1943999.917634360957891, 4934551.315987771376967 ], [ 1944192.436678836587816, 4934431.34713492076844 ], [ 1944088.338855499401689, 4934352.280027820728719 ], [ 1944036.347551923710853, 4934293.63498954847455 ], [ 1944015.320598123129457, 4934286.405173927545547 ], [ 1943937.751301430631429, 4934506.266459354199469 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 4 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1944022.752042065840214, 4934568.252766992896795 ], [ 1944153.55841732933186, 4934665.250392911955714 ], [ 1944202.33806974045001, 4934701.068224486894906 ], [ 1944211.065695769619197, 4934672.076951885595918 ], [ 1944230.076366328401491, 4934661.92352556437254 ], [ 1944255.438329096417874, 4934525.349140025675297 ], [ 1944254.574207707541063, 4934506.85694230068475 ], [ 1944240.705059413798153, 4934479.831545858643949 ], [ 1944218.324315439211205, 4934449.155236549675465 ], [ 1944215.904775549424812, 4934447.686230187304318 ], [ 1944022.752042065840214, 4934568.252766992896795 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 5 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1944306.032636424526572, 4934394.693986006081104 ], [ 1944342.62817725003697, 4934405.884357993490994 ], [ 1944325.432161608012393, 4934476.396663338877261 ], [ 1944336.406503248726949, 4934481.581391673535109 ], [ 1944443.643967628013343, 4934464.126139614731073 ], [ 1944449.519993073306978, 4934456.176222835667431 ], [ 1944460.58074685302563, 4934447.707833223976195 ], [ 1944467.234481548657641, 4934445.11546905618161 ], [ 1944476.221343995071948, 4934377.195527879521251 ], [ 1944495.836899525718763, 4934320.509164758957922 ], [ 1944505.169410527218133, 4934290.264916142448783 ], [ 1944509.835666028084233, 4934272.464015528559685 ], [ 1944403.030262343352661, 4934336.754646872170269 ], [ 1944306.032636424526572, 4934394.693986006081104 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 6 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1944271.683811210561544, 4934385.145444659516215 ], [ 1944482.529430134920403, 4934257.255479082465172 ], [ 1944114.759366961661726, 4934076.481284495443106 ], [ 1944055.999112507328391, 4934185.014930958859622 ], [ 1944035.260199170559645, 4934234.442674411460757 ], [ 1944068.096811953932047, 4934251.725102191790938 ], [ 1944117.524555406766012, 4934308.757113868370652 ], [ 1944223.638661980163306, 4934369.936908211559057 ], [ 1944271.683811210561544, 4934385.145444659516215 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 7 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1945109.535910018254071, 4934765.013207278214395 ], [ 1944917.009664541343227, 4934767.432747167535126 ], [ 1944774.948108184151351, 4934981.389203093014657 ], [ 1944908.714099206728861, 4935181.51971679367125 ], [ 1944910.442341984715313, 4935180.482771126553416 ], [ 1944918.73790731956251, 4935193.271767684258521 ], [ 1944916.664015985792503, 4935193.617416240274906 ], [ 1944920.811798653099686, 4935201.567333019338548 ], [ 1945136.150848800549284, 4934858.338317295536399 ], [ 1945083.266619791742414, 4934820.316976177506149 ], [ 1945109.535910018254071, 4934765.013207278214395 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 8 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1944974.041676217922941, 4934714.894166715443134 ], [ 1945136.323673078557476, 4934711.783329715020955 ], [ 1945221.180393481859937, 4934550.019805687479675 ], [ 1945092.599130793940276, 4934477.433609008789063 ], [ 1944974.041676217922941, 4934714.894166715443134 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 9 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1945449.308440186781809, 4933938.39468652755022 ], [ 1945476.960324635962024, 4933955.331465752795339 ], [ 1945502.883966306922957, 4933977.452973311766982 ], [ 1945535.029281979193911, 4933987.822429980151355 ], [ 1945635.958660218399018, 4933631.458769142627716 ], [ 1945551.966061204206198, 4933594.820022247731686 ], [ 1945449.308440186781809, 4933938.39468652755022 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 10 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1945535.067087289644405, 4933987.817029222846031 ], [ 1945615.592399238375947, 4934001.502551724202931 ], [ 1945796.971478816121817, 4933381.495455032214522 ], [ 1945766.900054474826902, 4933370.780349806882441 ], [ 1945771.393485698150471, 4933354.534867691807449 ], [ 1945640.39268310694024, 4933296.465910341590643 ], [ 1945639.70138599560596, 4933300.268044454045594 ], [ 1945639.010088884271681, 4933300.268044454045594 ], [ 1945551.798637687461451, 4933594.674201727844775 ], [ 1945635.985664022620767, 4933631.46416987106204 ], [ 1945535.067087289644405, 4933987.817029222846031 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 11 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1945509.413483551237732, 4933260.777696973644197 ], [ 1945585.110517238499597, 4933283.93615020159632 ], [ 1945586.1474629053846, 4933280.479664645157754 ], [ 1945598.072338075377047, 4933283.763325924053788 ], [ 1945597.55386524181813, 4933287.046987202949822 ], [ 1945638.68604336399585, 4933299.490335206501186 ], [ 1945727.805762629257515, 4932997.739146125502884 ], [ 1945525.255709018092602, 4932885.518581723794341 ], [ 1945476.404046486131847, 4933051.890753176994622 ], [ 1945560.281429324066266, 4933080.464367111213505 ], [ 1945509.413483551237732, 4933260.777696973644197 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 12 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1945509.240659272763878, 4933260.604872697964311 ], [ 1945560.273028179304674, 4933080.469167781993747 ], [ 1945476.387244127923623, 4933051.883552149869502 ], [ 1945525.236506320303306, 4932885.019311579875648 ], [ 1945467.878048778977245, 4932851.356982799246907 ], [ 1945435.387084546964616, 4932852.970009392127395 ], [ 1945429.626275286078453, 4932858.500386282801628 ], [ 1945425.939357359195128, 4932864.9524926552549 ], [ 1945373.170344528742135, 4933218.205316537059844 ], [ 1945509.240659272763878, 4933260.604872697964311 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 13 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1943865.30852407659404, 4933769.981028574518859 ], [ 1943875.677980750333518, 4933769.981028574518859 ], [ 1944015.396808079443872, 4933429.478795727714896 ], [ 1944016.318537561455742, 4933381.548862658441067 ], [ 1944003.107081651221961, 4933381.241619497537613 ], [ 1944002.185352169210091, 4933374.789513122290373 ], [ 1944007.71572906174697, 4933365.264975140802562 ], [ 1943983.750762527110055, 4933349.902817105874419 ], [ 1943983.750762527110055, 4933372.946054158732295 ], [ 1943980.063844598596916, 4933373.56054047960788 ], [ 1943981.292817241512239, 4933385.850266908295453 ], [ 1943990.510112062562257, 4933386.771996390074492 ], [ 1943989.895625741221011, 4933432.243984173983335 ], [ 1943985.594221491366625, 4933444.533710601739585 ], [ 1943976.069683509645984, 4933461.432084440253675 ], [ 1943961.322011795826256, 4933474.643540350720286 ], [ 1943942.272935832384974, 4933484.475321493111551 ], [ 1943912.470349244307727, 4933486.626023618504405 ], [ 1943851.021717103663832, 4933637.175172361545265 ], [ 1943865.30852407659404, 4933769.981028574518859 ] ] ] ] } },
    { "type": "Feature", "properties": { "id": 14 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1944111.563917378662154, 4935588.169242816977203 ], [ 1944276.246251514647156, 4935670.510409885086119 ], [ 1944334.622452047886327, 4935670.81765304505825 ], [ 1944357.972932261181995, 4935303.662076006643474 ], [ 1944135.836127074202523, 4935300.282401237636805 ], [ 1944133.378181788371876, 4935351.899252235889435 ], [ 1944196.055786571698263, 4935378.629407216794789 ], [ 1944112.1784037000034, 4935537.166878138668835 ], [ 1944111.563917378662154, 4935588.169242816977203 ] ] ] ] } }
    ]

}
const geojsonAsset = {
    "type": "FeatureCollection",
    "name": "particelle",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3857" } },
    "features": [
        { "type": "Feature", "properties": { "id": 1 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1943614.944354484789073, 4934189.863612042739987 ], [ 1943605.189384135883301, 4934197.506285662762821 ], [ 1943605.458221901440993, 4934199.503366206772625 ], [ 1943606.956032309215516, 4934202.268554652109742 ], [ 1943592.438792970264331, 4934210.180066036991775 ], [ 1943590.28809084603563, 4934208.336607073433697 ], [ 1943577.998364422237501, 4934213.406119223684072 ], [ 1943534.677078778157011, 4934300.355933672748506 ], [ 1943538.363996705273166, 4934320.787603852339089 ], [ 1943751.129885418107733, 4934474.255562569946051 ], [ 1943848.372345746960491, 4934543.07803054433316 ], [ 1943911.049950508400798, 4934404.511365114711225 ], [ 1943614.944354484789073, 4934189.863612042739987 ] ] ] ] } },
        { "type": "Feature", "properties": { "id": 2 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1943911.337990971049294, 4934404.684189390391111 ], [ 1943962.954841945786029, 4934281.460479311645031 ], [ 1943953.814357919152826, 4934263.947619159705937 ], [ 1943845.319116845726967, 4934255.72886461392045 ], [ 1943780.106756016612053, 4934213.214092272333801 ], [ 1943715.931340854382142, 4934191.783881822600961 ], [ 1943624.219257426680997, 4934183.718748857267201 ], [ 1943622.491014648461714, 4934184.179613597691059 ], [ 1943615.117178794927895, 4934189.709990488365293 ], [ 1943911.337990971049294, 4934404.684189390391111 ] ] ] ] } },
        { "type": "Feature", "properties": { "id": 3 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1943937.751301430631429, 4934506.266459354199469 ], [ 1943999.917634360957891, 4934551.315987771376967 ], [ 1944192.436678836587816, 4934431.34713492076844 ], [ 1944088.338855499401689, 4934352.280027820728719 ], [ 1944036.347551923710853, 4934293.63498954847455 ], [ 1944015.320598123129457, 4934286.405173927545547 ], [ 1943937.751301430631429, 4934506.266459354199469 ] ] ] ] } },
        { "type": "Feature", "properties": { "id": 4 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1944022.752042065840214, 4934568.252766992896795 ], [ 1944153.55841732933186, 4934665.250392911955714 ], [ 1944202.33806974045001, 4934701.068224486894906 ], [ 1944211.065695769619197, 4934672.076951885595918 ], [ 1944230.076366328401491, 4934661.92352556437254 ], [ 1944255.438329096417874, 4934525.349140025675297 ], [ 1944254.574207707541063, 4934506.85694230068475 ], [ 1944240.705059413798153, 4934479.831545858643949 ], [ 1944218.324315439211205, 4934449.155236549675465 ], [ 1944215.904775549424812, 4934447.686230187304318 ], [ 1944022.752042065840214, 4934568.252766992896795 ] ] ] ] } },
        { "type": "Feature", "properties": { "id": 5 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1944306.032636424526572, 4934394.693986006081104 ], [ 1944342.62817725003697, 4934405.884357993490994 ], [ 1944325.432161608012393, 4934476.396663338877261 ], [ 1944336.406503248726949, 4934481.581391673535109 ], [ 1944443.643967628013343, 4934464.126139614731073 ], [ 1944449.519993073306978, 4934456.176222835667431 ], [ 1944460.58074685302563, 4934447.707833223976195 ], [ 1944467.234481548657641, 4934445.11546905618161 ], [ 1944476.221343995071948, 4934377.195527879521251 ], [ 1944495.836899525718763, 4934320.509164758957922 ], [ 1944505.169410527218133, 4934290.264916142448783 ], [ 1944509.835666028084233, 4934272.464015528559685 ], [ 1944403.030262343352661, 4934336.754646872170269 ], [ 1944306.032636424526572, 4934394.693986006081104 ] ] ] ] } },
        { "type": "Feature", "properties": { "id": 6 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1944271.683811210561544, 4934385.145444659516215 ], [ 1944482.529430134920403, 4934257.255479082465172 ], [ 1944114.759366961661726, 4934076.481284495443106 ], [ 1944055.999112507328391, 4934185.014930958859622 ], [ 1944035.260199170559645, 4934234.442674411460757 ], [ 1944068.096811953932047, 4934251.725102191790938 ], [ 1944117.524555406766012, 4934308.757113868370652 ], [ 1944223.638661980163306, 4934369.936908211559057 ], [ 1944271.683811210561544, 4934385.145444659516215 ] ] ] ] } },
        { "type": "Feature", "properties": { "id": 7 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1945109.535910018254071, 4934765.013207278214395 ], [ 1944917.009664541343227, 4934767.432747167535126 ], [ 1944774.948108184151351, 4934981.389203093014657 ], [ 1944908.714099206728861, 4935181.51971679367125 ], [ 1944910.442341984715313, 4935180.482771126553416 ], [ 1944918.73790731956251, 4935193.271767684258521 ], [ 1944916.664015985792503, 4935193.617416240274906 ], [ 1944920.811798653099686, 4935201.567333019338548 ], [ 1945136.150848800549284, 4934858.338317295536399 ], [ 1945083.266619791742414, 4934820.316976177506149 ], [ 1945109.535910018254071, 4934765.013207278214395 ] ] ] ] } },
        { "type": "Feature", "properties": { "id": 8 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1944974.041676217922941, 4934714.894166715443134 ], [ 1945136.323673078557476, 4934711.783329715020955 ], [ 1945221.180393481859937, 4934550.019805687479675 ], [ 1945092.599130793940276, 4934477.433609008789063 ], [ 1944974.041676217922941, 4934714.894166715443134 ] ] ] ] } },
        { "type": "Feature", "properties": { "id": 9 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1945449.308440186781809, 4933938.39468652755022 ], [ 1945476.960324635962024, 4933955.331465752795339 ], [ 1945502.883966306922957, 4933977.452973311766982 ], [ 1945535.029281979193911, 4933987.822429980151355 ], [ 1945635.958660218399018, 4933631.458769142627716 ], [ 1945551.966061204206198, 4933594.820022247731686 ], [ 1945449.308440186781809, 4933938.39468652755022 ] ] ] ] } },
        { "type": "Feature", "properties": { "id": 10 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1945535.067087289644405, 4933987.817029222846031 ], [ 1945615.592399238375947, 4934001.502551724202931 ], [ 1945796.971478816121817, 4933381.495455032214522 ], [ 1945766.900054474826902, 4933370.780349806882441 ], [ 1945771.393485698150471, 4933354.534867691807449 ], [ 1945640.39268310694024, 4933296.465910341590643 ], [ 1945639.70138599560596, 4933300.268044454045594 ], [ 1945639.010088884271681, 4933300.268044454045594 ], [ 1945551.798637687461451, 4933594.674201727844775 ], [ 1945635.985664022620767, 4933631.46416987106204 ], [ 1945535.067087289644405, 4933987.817029222846031 ] ] ] ] } },
        { "type": "Feature", "properties": { "id": 11 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1945509.413483551237732, 4933260.777696973644197 ], [ 1945585.110517238499597, 4933283.93615020159632 ], [ 1945586.1474629053846, 4933280.479664645157754 ], [ 1945598.072338075377047, 4933283.763325924053788 ], [ 1945597.55386524181813, 4933287.046987202949822 ], [ 1945638.68604336399585, 4933299.490335206501186 ], [ 1945727.805762629257515, 4932997.739146125502884 ], [ 1945525.255709018092602, 4932885.518581723794341 ], [ 1945476.404046486131847, 4933051.890753176994622 ], [ 1945560.281429324066266, 4933080.464367111213505 ], [ 1945509.413483551237732, 4933260.777696973644197 ] ] ] ] } },
        { "type": "Feature", "properties": { "id": 12 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1945509.240659272763878, 4933260.604872697964311 ], [ 1945560.273028179304674, 4933080.469167781993747 ], [ 1945476.387244127923623, 4933051.883552149869502 ], [ 1945525.236506320303306, 4932885.019311579875648 ], [ 1945467.878048778977245, 4932851.356982799246907 ], [ 1945435.387084546964616, 4932852.970009392127395 ], [ 1945429.626275286078453, 4932858.500386282801628 ], [ 1945425.939357359195128, 4932864.9524926552549 ], [ 1945373.170344528742135, 4933218.205316537059844 ], [ 1945509.240659272763878, 4933260.604872697964311 ] ] ] ] } },
        { "type": "Feature", "properties": { "id": 13 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1943865.30852407659404, 4933769.981028574518859 ], [ 1943875.677980750333518, 4933769.981028574518859 ], [ 1944015.396808079443872, 4933429.478795727714896 ], [ 1944016.318537561455742, 4933381.548862658441067 ], [ 1944003.107081651221961, 4933381.241619497537613 ], [ 1944002.185352169210091, 4933374.789513122290373 ], [ 1944007.71572906174697, 4933365.264975140802562 ], [ 1943983.750762527110055, 4933349.902817105874419 ], [ 1943983.750762527110055, 4933372.946054158732295 ], [ 1943980.063844598596916, 4933373.56054047960788 ], [ 1943981.292817241512239, 4933385.850266908295453 ], [ 1943990.510112062562257, 4933386.771996390074492 ], [ 1943989.895625741221011, 4933432.243984173983335 ], [ 1943985.594221491366625, 4933444.533710601739585 ], [ 1943976.069683509645984, 4933461.432084440253675 ], [ 1943961.322011795826256, 4933474.643540350720286 ], [ 1943942.272935832384974, 4933484.475321493111551 ], [ 1943912.470349244307727, 4933486.626023618504405 ], [ 1943851.021717103663832, 4933637.175172361545265 ], [ 1943865.30852407659404, 4933769.981028574518859 ] ] ] ] } },
        { "type": "Feature", "properties": { "id": 14 }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 1944111.563917378662154, 4935588.169242816977203 ], [ 1944276.246251514647156, 4935670.510409885086119 ], [ 1944334.622452047886327, 4935670.81765304505825 ], [ 1944357.972932261181995, 4935303.662076006643474 ], [ 1944135.836127074202523, 4935300.282401237636805 ], [ 1944133.378181788371876, 4935351.899252235889435 ], [ 1944196.055786571698263, 4935378.629407216794789 ], [ 1944112.1784037000034, 4935537.166878138668835 ], [ 1944111.563917378662154, 4935588.169242816977203 ] ] ] ] } }
        ]
    }

export {geojsonObject, geojsonAsset};




    