
export default class Progress {
    constructor(el) {
        this.el = el;
        this.loading = 0;
        this.loaded = 0;
    }
    update() {
        const width = ((this.loaded / this.loading) * 100).toFixed(1) + '%';
        this.el.style.width = width;
    }
    addLoading() {
        ++this.loading;
        this.update();
    }
    addLoaded() {
        ++this.loaded;
        this.update();
    }
    show() {
        this.el.style.visibility = 'visible';
    }
    hide() {
        const style = this.el.style;
        setTimeout(function () {
            style.visibility = 'hidden';
            style.width = 0;
        }, 250);
    }
}
