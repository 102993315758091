import "ol/ol.css";
import "./style.css";
import "./index.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import WebGLTileLayer from "ol/layer/WebGLTile";
import ImageLayer from "ol/layer/Image";
import ImageWMS from "ol/source/ImageWMS";
import Group from "ol/layer/Group";
import Static from "ol/source/ImageStatic";
import TileWMS from "ol/source/TileWMS";
import GeoTIFF from "ol/source/GeoTIFF";
import { getRenderPixel } from "ol/render";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import Projection from "ol/proj/Projection";
import proj4 from "proj4";
import { fromLonLat } from "ol/proj";
import { register } from "ol/proj/proj4";
import { transform } from "ol/proj";
import { getCenter } from "ol/extent";
import Overlay from "ol/Overlay";
import { toStringHDMS } from "ol/coordinate";
import Progress from "./Progress";
import GeoJSON from 'ol/format/GeoJSON';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import {geojsonObject, geojsonAsset }from './img/geom/nuove/particelle'

// import $ from "jquery";
// import $ from "jquery";
// window.$ = $;
// import 'bootstrap'; 
// import {$,jQuery} from 'jquery';
// // export for others scripts to use
// window.$ = $;
// window.jQuery = jQuery;
// import './jquery-global.js';
// import 'bootstrap'; 

// import JQuery from 'jquery';
// window.$ = window.JQuery = JQuery;
// import * from  'bootstrap';

const styles = {
  'MultiPolygon': new Style({
    stroke: new Stroke({
      color: 'green',
  
      width: 0.2,
    }),
    fill: new Fill({
      color: 'rgba(16,146,134, 0.5)',
    }),
  })
}
const stylesAsset = {
  'MultiPolygon': new Style({
    stroke: new Stroke({
      color: 'red',
  
      width: 2,
    }),
    // fill: new Fill({
    //   color: 'rgba(255,0,0, 0.2)',
    // }),
  })
}

const styleFunction = function (feature) {
  // console.log( styles[feature.getGeometry().getType()])
  return styles[feature.getGeometry().getType()];
};
const styleFunctionAsset = function (feature) {
  // console.log( styles[feature.getGeometry().getType()])
  return stylesAsset[feature.getGeometry().getType()];
};
const vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(geojsonObject),
});

const vectorAsset = new VectorSource({
  features: new GeoJSON().readFeatures(geojsonAsset),
});


proj4.defs(
  "EPSG:6706",
  "+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs"
);
register(proj4);


const projection = new Projection({
  code: "EPSG:6706",
  extent: [5.93, 34.76, 18.99, 47.1],
});
const projection3857 = new Projection({
  code: "EPSG:3857",
  extent: [-180.00, -85.06, 180.00, 85.06],
});

const imgExtent = [
  1941072.2719317250885069,4932132.4187480136752129 , 1946850.5979924215935171,4935780.3864447055384517
];
const imgExtent_6706 = [
  17.4369488950000004,40.4539664879999989 , 17.4888564809999991,40.4788976469999966
];

var provincesource = new ImageWMS({
  projection: "EPSG:6706",
  url: "https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php",
  params: {
    LAYERS: "province",
    VERSION: "1.1.1",
  },
  serverType: "mapserver",
});
var provinceLayer = new ImageLayer({
  title: "province",
  visible: true,
  maxZoom: 11,
  source: provincesource,
  opacity: 0.5,
});
var fogliLayer = new ImageLayer({
  title: "Fogli",
  visible: true,
  minZoom: 16,
  opacity: 0.5,
  source: new ImageWMS({
    projection: "EPSG:6706",
    url: "https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php",
    params: {
      LAYERS: "CP.CadastralZoning",
      TILED: true,
      VERSION: "1.1.1",
    },
    serverType: "mapserver",
  }),
});
var cadastralParcelSource = new ImageWMS({
  projection: "EPSG:6706",
  url: "https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php",
  params: {
    LAYERS: "CP.CadastralParcel",
    VERSION: "1.1.1",
  },
  serverType: "mapserver",
});
var cadastralParcelLayer = new ImageLayer({
  title: "Particelle",
  visible: true,
  minZoom: 16,
  source: cadastralParcelSource,
  opacity: 0.5,
});
var fabbricatiLayer = new ImageLayer({
  title: "Fabbricati",
  visible: true,
  minZoom: 16,
  opacity: 0.5,
  source: new ImageWMS({
    projection: "EPSG:6706",
    url: "https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php",
    params: {
      LAYERS: "fabbricati",
      TILED: true,
      VERSION: "1.1.1",
    },
    serverType: "mapserver",
  }),
});
var vestizioniLayer = new ImageLayer({
  title: "Vestizioni",
  visible: true,
  minZoom: 16,
  opacity: 0.5,
  source: new ImageWMS({
    projection: "EPSG:6706",
    url: "https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php",
    params: {
      LAYERS: "vestizioni",
      TILED: true,
      VERSION: "1.1.1",
    },
    serverType: "mapserver",
  }),
});

/**
 * Elements that make up the popup.
 */
const container = document.getElementById("popup");
const content = document.getElementById("popup-content");
const closer = document.getElementById("popup-closer");

const sliderImageSxa = document.getElementById("myRange_sinistra");
const outputValueSxa = document.getElementById("imagevalue_sinistra");

const sliderImageDxs = document.getElementById("myRange_destra");
const outputValueDxs = document.getElementById("imagevalue_destra");
const swipeSx = document.getElementsByClassName("float-sinistra");
const swipeDx = document.getElementsByClassName("float-destra");
/**
 * Create an overlay to anchor the popup to the map.
 */
const overlay = new Overlay({
  element: container,
  autoPan: {
    animation: {
      duration: 250,
    },
  },
});



/**
 * Add a click handler to hide the popup.
 * @return {boolean} Don't follow the href.
 */
closer.onclick = function () {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};

const view_6706 = new View({
  projection: projection,
});

const map_6706 = new Map({
  layers: [],
  view: view_6706,
});


const view = new View({
  center: transform(
    [17.466091791039766, 40.46498441417021],
    projection,
    "EPSG:3857",
  ),
  // center: transform([0, 0,], projection, 'EPSG:3857'),
  zoom: 15,
  
});

const osm = new TileLayer({
  source: new OSM(),
});

const esri = new TileLayer({
  source: new XYZ({
    attributions:
      'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
      'rest/services/World_Topo_Map/MapServer">ArcGIS</a>',
    url:
      'https://server.arcgisonline.com/ArcGIS/rest/services/' +
      'World_Imagery/MapServer/tile/{z}/{y}/{x}',
  }),
 
});

const sourceLayers = {
  // 2003: "http://localhost:3000/img/img_earth/png/2003_6706.png",
  2009: "./img/img_earth/png/2009_6706.png",
  2013: "./img/img_earth/png/2013_6706.png",
  // 2015: "http://localhost:3000/img/img_earth/png/2015.png",
  // 2017: "http://localhost:3000/img/img_earth/png/2017.png",
  // 2018: "http://localhost:3000/img/img_earth/png/2018_6706.png",
};

const selectElement = function (id, valueToSelect) {
  let element = document.getElementById(id);
  element.value = valueToSelect;
};

for (const [key, value] of Object.entries(sourceLayers)) {
  let opt = document.createElement("option");
  opt.value = key;
  opt.innerHTML = String(key);
  sliderImageSxa.appendChild(opt);
  selectElement("myRange_sinistra", "2009");
}

for (const [key, value] of Object.entries(sourceLayers)) {
  let opt = document.createElement("option");
  opt.value = key;
  opt.innerHTML = String(key);
  sliderImageDxs.appendChild(opt);
  selectElement("myRange_destra", "2013");
}

const ImgPre = new ImageLayer({
  source: new Static({
    // attributions: '© <a href="https://xkcd.com/license.html">xkcd</a>',
    url: "./img/img_earth/png/2009_6706.png",
    
    projection: "EPSG:6706",
    imageExtent: imgExtent_6706,
  }),
});

const ImgPost = new ImageLayer({
  source: new Static({
    // attributions: '© <a href="https://xkcd.com/license.html">xkcd</a>',
    // url: "http://localhost:3000/img/img_earth/png/2018.png",
    url: "./img/img_earth/png/2013_6706.png",

    projection: "EPSG:6706",
    // imageExtent: imgExtent,
    imageExtent: imgExtent_6706,
  }),
});
const geojsonLayer = new VectorLayer({
  source: vectorSource,
  style: styleFunction,
  projection: "EPSG:3857",
});
const geojsonAssetLayers = new VectorLayer({
  source: vectorAsset,
  style: styleFunctionAsset,
  projection: "EPSG:3857",
});

const groupCatasto = new Group({
  layers: [
    provinceLayer,
    fogliLayer,
    cadastralParcelLayer,
    fabbricatiLayer,
    vestizioniLayer,
  ],
});
const groupLayersImg = new Group({
  layers: [esri,ImgPre ],
});

const map = new Map({
  target: "map",
  layers: [groupLayersImg, ImgPost,  groupCatasto,geojsonLayer,geojsonAssetLayers  ],
  overlays: [overlay],
  view: view,
});

const swipe = document.getElementById("swipe");

map.on("singleclick", function (evt) {
  if (fabbricatiLayer.getVisible()) {
    view_6706.setCenter(transform(view.getCenter(), "EPSG:3857", projection));
    view_6706.setZoom(view.getZoom());
    var viewResolution = view_6706.getResolution();
    var url = cadastralParcelSource.getFeatureInfoUrl(
      transform(evt.coordinate, "EPSG:3857", projection),
      view_6706.getResolution(),
      projection.getCode(),
      {
        INFO_FORMAT: "text/html",
        QUERY_LAYERS: ["CP.CadastralParcel"],
      }
    );
    //console.log(url);
    content.innerHTML =
      '<code><img style="width:100%;" src="img/giphy.gif" ></code>';
    overlay.setPosition(evt.coordinate);
    if (url) {
      var xhttp;
      xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          content.innerHTML = "<code>" + xhttp.responseText + "</code>";
        }
      };
      //bypass cors policy
      xhttp.open("GET", "https://proxy.innovazioniperlaterra.org/" + url, true);
      xhttp.send();
    }
  }
});

const progress = new Progress(document.getElementById("progress"));

provincesource.on("imageloadstart", function () {
  progress.show();
  progress.addLoading();
});
provincesource.on(["imageloadend", "imageloaderror"], function () {
  progress.addLoaded();
  progress.hide();
});

cadastralParcelSource.on("imageloadstart", function () {
  progress.show();
  progress.addLoading();
});
cadastralParcelSource.on(["imageloadend", "imageloaderror"], function () {
  progress.addLoaded();
  progress.hide();
});

window.addEventListener("resize", function () {
  var actualZoom = view.getZoom();
  console.log(actualZoom);
});

ImgPost.on("prerender", function (event) {
  const ctx = event.context;
  const mapSize = map.getSize();
  const width = mapSize[0] * (swipe.value / 100);
  const tl = getRenderPixel(event, [width, 0]);
  const tr = getRenderPixel(event, [mapSize[0], 0]);
  const bl = getRenderPixel(event, [width, mapSize[1]]);
  const br = getRenderPixel(event, mapSize);

  let calcoloSx = Number(width) - 152;

  swipeSx[0].style.left = `${calcoloSx}px`;

  let calcoloDx = Number(width) + 20;
  swipeDx[0].style.left = `${calcoloDx}px`;

  ctx.save();
  ctx.beginPath();
  ctx.moveTo(tl[0], tl[1]);
  ctx.lineTo(bl[0], bl[1]);
  ctx.lineTo(br[0], br[1]);
  ctx.lineTo(tr[0], tr[1]);
  ctx.closePath();
  ctx.clip();
});

ImgPost.on("postrender", function (event) {
  const ctx = event.context;
  ctx.restore();
});

const catastoinput = document.getElementById("catastovisible");
const particelleinput = document.getElementById("particellevisible");

const visibleparticelle = function () {

  geojsonLayer.setVisible(particelleinput.checked);
};

const visiblecatasto = function () {
  groupCatasto.getLayers().forEach(function (layer, i) {
    layer.setVisible(catastoinput.checked);
  });
};

const changeOpacity = function () {
  groupCatasto.getLayers().forEach(function (layer, i) {
    layer.setOpacity(parseFloat(slidercatasto.value / 100));
  });
};
const listener = function () {
  map.render();
};

outputValueDxs.innerHTML = sliderImageDxs.value; // Display the default slider value
// Update the current slider value (each time you drag the slider handle)
sliderImageDxs.oninput = function () {
  outputValueDxs.innerHTML = this.value;
  changeImgStaticSource(ImgPost, sourceLayers[this.value], "EPSG:3857", imgExtent);
};

outputValueSxa.innerHTML = sliderImageSxa.value; // Display the default slider value
// Update the current slider value (each time you drag the slider handle)
sliderImageSxa.oninput = function () {
  outputValueSxa.innerHTML = this.value;
  changeImgStaticSource(ImgPre, sourceLayers[this.value], "EPSG:3857", imgExtent);
};
const slidercatasto = document.getElementById("range_opacita");

catastoinput.addEventListener("change", visiblecatasto);
particelleinput.addEventListener("change", visibleparticelle);
slidercatasto.addEventListener("input", changeOpacity);
slidercatasto.addEventListener("change", changeOpacity);
swipe.addEventListener("input", listener);
swipe.addEventListener("change", listener);
catastoinput.checked = true;
particelleinput.checked = true;
window.catastoinput = catastoinput;

geojsonLayer.setVisible(true);
ImgPre.setVisible(false);
ImgPost.setVisible(false);
geojsonAssetLayers.setVisible(false);

const changeImgStaticSource = function (layer, url, projection, extent) {
  let source = new Static({
    // attributions: '© <a href="https://xkcd.com/license.html">xkcd</a>',
    url: url,
    projection: projection,
    imageExtent: extent,
  });
  layer.setSource(source);
};

// $.noConflict();
//var myModal = new bootstrap.Modal(document.getElementById('basiceModal'), )
// $('#basiceModal').on('closed.bs.alert', function () {
//   // do something…
// })

// setTimeout(function(){
//   $("#basiceModal").hide();
// }, 3000);
// // var $modalDialog = $("#basiceModal");
// $modalDialog.modal('show');

// setTimeout(function() {
//   $modalDialog.modal('hide');
// }, 3000);

const swipevisibility = document.getElementsByClassName('swipevisibility');



const collaps_sinistra = document.getElementById('collaps_sinistra')
const selectSis = document.getElementById('collapseSis');
window.collaps_sinistra =collaps_sinistra
collaps_sinistra.addEventListener('click', () => {
  if (selectSis.classList.contains("show")) {
    selectSis.classList.remove('show');
    collaps_sinistra.textContent="expand_less";
  }
  else{  selectSis.classList.add('show');
  collaps_sinistra.textContent="expand_more";
}

})
const collaps_destra = document.getElementById('collaps_destra')
const selectDex = document.getElementById('collapseDex');
collaps_destra.addEventListener('click', () => {
  if (selectDex.classList.contains("show")) {
    selectDex.classList.remove('show');
    collaps_destra.textContent="expand_less";
  }
  else{  selectDex.classList.add('show');
  collaps_destra.textContent="expand_more";}

})

const myModal = document.getElementById('buttonAsset')
const alert = document.getElementById('alert');
myModal.addEventListener('click', () => {
//     $("#myModal").modal("show");
alert.classList.remove('d-none');
//alert.close()
const point = fromLonLat([ 1943962.69,4934327.47]);
setTimeout(() => {
  const swipe = document.getElementById('swipe');
  swipe.classList.remove('notvisible')
  const floatdestra = document.getElementById('floatdestra');
  floatdestra.classList.remove('notvisible')
  const floatsinistra = document.getElementById('floatsinistra');
  floatsinistra.classList.remove('notvisible')
  ImgPre.setVisible(true);
  ImgPost.setVisible(true);
  // geojsonObject.setVisible(false);
  geojsonAssetLayers.setVisible(true);
  let extent = geojsonAssetLayers.getExtent()
  
 alert.classList.add('d-none');
console.log("chiuso")
// sourceLayers["2013"] = "http://localhost:3000/img/img_earth/png/2018_6706_asset.png"
changeImgStaticSource(ImgPost, sourceLayers["2013"], "EPSG:3857", imgExtent);
view.animate({  zoom: 16,
  center: [1943962.69,4934327.47],
  duration: 2000,}
);
}, 4000)


})

  // $('#buttonSwipe').on('click', function () {
  //   $("#dialog").dialog();
  // });

  // $('#exampleModalCenter').on('shown.bs.modal', function () {
  //   $('#myInput').trigger('focus')
  // })

  // document.addEventListener("DOMContentLoaded", function(){  // DOM is ready 
  //   const myModal = document.getElementById('buttonSwipe')
  // myModal.addEventListener('click', () => {
  // //     $("#myModal").modal("show");
  // $("#exampleModal").modal("show");
  // })
  // });
